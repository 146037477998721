/* eslint-disable import/no-named-as-default-member */
import { useLocale } from "next-intl";
import { usePathname, useRouter } from "next/navigation";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import {
  useAuthLogout,
  useAuthRequest,
  useAuthVerify,
} from "../hooks/useAuthQuery";
import {
  clearForestDashboardStorage,
  clearProjectDashboardStorage,
} from "../hooks/useDashboardStore";
import { clearForestStore, useForestStore } from "../hooks/useForestStore";
import { useUser } from "../hooks/useUserQuery";
import { clearUserStore, useUserStore } from "../hooks/useUserStore";
import { User } from "../types/user.type";
import { apiAuthSession, authenticatedApi } from "../utils/api";
import gtag from "../utils/gtag";

type AuthContextType = {
  isAuthenticated?: boolean;
  isLoading: boolean;
  isError?: boolean;
  login: (
    email: string,
    code: string,
  ) => Promise<void | { status: "OK" | "ERROR"; message: string }>;
  logout: () => Promise<void>;
  requestCode: (email: string) => Promise<{
    status: "OK" | "ERROR";
    message: string;
  }>;
  refreshToken: () => Promise<void>;
  user: User | null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 60 * 1000,
      cacheTime: 60 * 60 * 1000,
    },
  },
});

const revalidateAuthentication = async (): Promise<User | null> => {
  try {
    const response = await authenticatedApi.get<{ data: User }>("/user/me");
    return response.data.data;
  } catch (error) {
    return null;
  }
};

export const AuthProvider: React.FC<{
  children: React.ReactNode;
  isPublic?: boolean;
}> = ({ children, isPublic = false }) => {
  const router = useRouter();
  const pathname = usePathname();
  const locale = useLocale();

  apiAuthSession.setLocale(locale);

  const {
    mutateAsync: requestOTPCode,
    isLoading: isRequestingOTP,
    isError: isOTPRequestError,
  } = useAuthRequest();
  const {
    mutateAsync: verifyOTPCode,
    isLoading: isVerifyingOTP,
    isError: isOTPVerifyError,
  } = useAuthVerify();
  const {
    mutate: logoutUser,
    isLoading: isLoggingOut,
    isError: isLoggingOutError,
  } = useAuthLogout();

  const {
    user,
    isAuthenticated,
    isLoading: isUserLoading,
    isFetched: isUserFetched,
    isError,
    setUser,
    setIsAuthenticated,
  } = useUser();

  const { setHydrated } = useUserStore();

  const { forestCode, forest } = useForestStore();

  const isPageLoading =
    isUserLoading || isRequestingOTP || isVerifyingOTP || isLoggingOut;

  const redirectIfProtected = useCallback(() => {
    if (
      !pathname.includes("/login") &&
      !pathname.includes("/verify") &&
      !pathname.includes("/public")
    ) {
      router.push(`/${locale}/login`);
    }
  }, [pathname, locale, router]);

  useEffect(() => {
    if (isUserLoading || isRequestingOTP || isVerifyingOTP || isLoggingOut) {
      return;
    }
    if (isUserFetched) {
      if (user) {
        setIsAuthenticated(true);
      } else if (isError || (!isPublic && !user)) {
        setIsAuthenticated(false);
        setUser(null);
        redirectIfProtected();
      }
    }
  }, [
    user,
    isUserLoading,
    isLoggingOut,
    isUserFetched,
    isPublic,
    isRequestingOTP,
    isVerifyingOTP,
    isError,
    locale,
    setIsAuthenticated,
    setUser,
    redirectIfProtected,
  ]);

  useEffect(() => {
    const revalidateUser = async () => {
      if (isPublic) {
        return;
      }
      const user = await revalidateAuthentication();
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        setUser(null);
        redirectIfProtected();
      }
      setHydrated(true);
    };

    revalidateUser();
  }, [setUser, setIsAuthenticated, redirectIfProtected, setHydrated, isPublic]);

  const requestCode = async (email: string) => {
    return requestOTPCode(email, {
      onSuccess: (data) => {
        if (data.status === "OK") {
          const timestamp = Date.now();
          sessionStorage.setItem("emailForVerification", email);
          sessionStorage.setItem("codeRequestTimestamp", timestamp.toString());
        } else {
          sessionStorage.removeItem("emailForVerification");
          sessionStorage.removeItem("codeRequestTimestamp");

          gtag.event({
            name: "request_code_failed",
            params: {
              email,
              forestCode,
              forestName: forest?.name,
              accountName: forest?.account.title,
              membership: forest?.membership,
            },
          });
          Promise.reject(new Error(data.message));
        }
      },
      onError: () => {
        console.error("Error requesting code");
        gtag.event({
          name: "request_code_failed",
          params: {
            email,
            forestCode,
            forestName: forest?.name,
            accountName: forest?.account.title,
            membership: forest?.membership,
          },
        });
      },
    });
  };

  const login = async (email: string, code: string) => {
    return verifyOTPCode(
      { email, code },
      {
        onSuccess: (data) => {
          if (data.status === "OK") {
            setIsAuthenticated(true);
            sessionStorage.removeItem("emailForVerification");
            sessionStorage.removeItem("codeRequestTimestamp");
            gtag.event({
              name: "login_succeeded",
              params: {
                email,
                forestCode,
                forestName: forest?.name,
                accountName: forest?.account.title,
                membership: forest?.membership,
              },
            });
            router.push(`/${locale}/forest-selection`);
          } else {
            gtag.event({
              name: "login_failed",
              params: {
                email,
                forestCode,
                forestName: forest?.name,
                accountName: forest?.account.title,
                membership: forest?.membership,
              },
            });
            return Promise.reject(new Error(data.message));
          }
        },
        onError: (error) => {
          gtag.event({
            name: "login_failed",
            params: {
              email,
              forestCode,
              forestName: forest?.name,
              accountName: forest?.account.title,
              membership: forest?.membership,
            },
          });
          console.error("Error verifying code:", error);
        },
      },
    );
  };

  const logout = async () => {
    return logoutUser(undefined, {
      onSuccess: () => {
        clearUserStore();
        clearForestDashboardStorage();
        clearProjectDashboardStorage();
        clearForestStore();
        setIsAuthenticated(false);
        router.push(`/${locale}/login`);
      },
      onError: (error) => {
        console.error("Logout failed:", error);
      },
    });
  };

  const isUserAuthenticated = isAuthenticated && !!user;

  const refreshToken = async () => {
    try {
      await apiAuthSession.post("/auth/refresh");
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      router.push(`/${locale}/login`);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider
        value={{
          isAuthenticated: isUserAuthenticated,
          isLoading: isPageLoading,
          isError: isOTPRequestError || isOTPVerifyError || isLoggingOutError,
          login,
          logout,
          requestCode,
          refreshToken,
          user,
        }}
      >
        {children}
      </AuthContext.Provider>
    </QueryClientProvider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
