import axios, {
  AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";

function getCookie(name: string): string | null {
  if (typeof document === "undefined") {
    return null;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
}
export default class Api {
  private instance: AxiosInstance;
  private locale: string = "en";
  private interceptorId: number | null = null;

  constructor(baseURL: string, withCredentials: boolean = true) {
    this.instance = axios.create({
      baseURL,
      withCredentials,
      headers: {
        "Content-Type": "application/json",
      },
    });

    const nextLocale = getCookie("NEXT_LOCALE");
    if (nextLocale) {
      this.setLocale(nextLocale);
    } else {
      this.setLocale(this.locale);
    }
  }

  public setLocale(locale: string): void {
    this.locale = locale;

    if (this.interceptorId !== null) {
      this.instance.interceptors.request.eject(this.interceptorId);
    }
    this.interceptorId = this.instance.interceptors.request.use((config) => {
      config.headers["Accept-Language"] = this.locale;
      return config;
    });
  }

  private async request<T>(
    method: "get" | "post" | "put" | "delete",
    url: string,
    data?: object,
    params?: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    try {
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        withCredentials: true,
      };

      if (method === "get") {
        config.params = params;
      } else {
        config.data = data;
        config.params = params;
      }

      const response: AxiosResponse<T> = await this.instance.request<T>(config);
      return response;
    } catch (error) {
      if (error instanceof Error) {
        console.error("API call failed:", error);
      } else {
        console.error("API call failed with an unknown error");
      }
      throw error;
    }
  }

  public get<T>(
    url: string,
    params?: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("get", url, undefined, params, headers);
  }

  public post<T>(
    url: string,
    data?: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("post", url, data, undefined, headers);
  }

  public put<T>(
    url: string,
    data?: object,
    headers?: object,
  ): Promise<AxiosResponse<T>> {
    return this.request<T>("put", url, data, undefined, headers);
  }

  public delete(url: string, headers?: object): Promise<AxiosResponse> {
    return this.request("delete", url, undefined, undefined, headers);
  }

  public getInstance(): AxiosInstance {
    return this.instance;
  }
}

export const CFT_API_PROXY_PATH = "/api";

export const apiAuthSession = new Api(CFT_API_PROXY_PATH, false);

export const api = new Api(CFT_API_PROXY_PATH, true);

export const createProtectedApiInstance = (
  onTokenRefresh: (isRefreshing: boolean) => void,
): Api => {
  let isRefreshing = false;
  let failedQueue: any[] = [];
  const authenticatedApi = new Api(CFT_API_PROXY_PATH, true);
  const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  authenticatedApi.getInstance().interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config as InternalAxiosRequestConfig & {
        _retry?: boolean;
      };
      if (!originalRequest) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              return authenticatedApi.getInstance()(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        originalRequest._retry = true;
        isRefreshing = true;
        onTokenRefresh(isRefreshing);

        try {
          await api.post("/auth/refresh");
          isRefreshing = false;
          onTokenRefresh(isRefreshing);
          processQueue(null);
          return authenticatedApi.getInstance()(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          onTokenRefresh(false);
          processQueue(refreshError as AxiosError);
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    },
  );

  return authenticatedApi;
};

export const authenticatedApi = createProtectedApiInstance(
  (isRefreshing: boolean) => {},
);
