import { useLocale } from "next-intl";

import Typography from "../ui/Typography";

const Terms = () => {
  const locale = useLocale();

  if (locale === "fr") {
    return (
      <div className="max-w-3xl mx-auto p-5">
        <p className="mb-3">
          <Typography.Heading size="lg" className="mb-5">
            Corporation fonds des Forêts du Canada
          </Typography.Heading>
        </p>
        <p className="mb-3">
          <Typography.Heading size="md" className="mb-3">
            Conditions générales d’utilisation du site Web
          </Typography.Heading>
        </p>
        <p className="mb-3">
          EN UTILISANT LE SITE WEB, VOUS ACCEPTEZ LES PRÉSENTES MODALITÉS ET
          NOTRE POLITIQUE DE CONFIDENTIALITÉ INTÉGRÉE AUX PRÉSENTES PAR RENVOI,
          ET VOUS CONVENEZ D’ÊTRE LIÉ PAR CELLES-CI ET DE LES RESPECTER. SI VOUS
          N’ACCEPTEZ PAS CES MODALITÉS OU CETTE POLITIQUE DE CONFIDENTIALITÉ,
          VOUS NE DEVEZ PAS ACCÉDER AU SITE WEB NI L’UTILISER.
        </p>
        <ol className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Acceptation des modalités
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-4">
          Les présentes conditions générales d’utilisation du site{" "}
          <a href="http://www.canadasforesttrust.ca" target="_blank">
            http://www.canadasforesttrust.ca
          </a>{" "}
          (ci-après désigné le « site Web ») constituent une entente juridique
          conclue entre vous et Canada’s Forest Trust Corporation (ci-après
          désignée « CFT », « nous », « notre », « nos »). Les modalités
          suivantes, ainsi que notre Politique de confidentialité (ci-après
          désignées collectivement les « modalités »), régissent votre accès au
          site Web et votre utilisation de celui-ci.
        </p>

        <p className="mb-3">
          En utilisant ce site Web, vous déclarez et garantissez que vous avez
          l’âge de la majorité en vertu de la loi applicable pour conclure un
          contrat obligatoire avec CFT et que vous satisfaites à toutes les
          conditions d’admissibilité mentionnées aux présentes. Si vous n’êtes
          pas majeur, vous ne pouvez consulter ou utiliser le site Web qu’avec
          le consentement de votre parent ou de votre tutrice ou tuteur légal.
          Veuillez vous assurer que votre parent ou votre tutrice ou tuteur
          légal a passé en revue les présentes modalités et la Politique de
          confidentialité et en a discuté avec vous.
        </p>

        <p className="mb-3">
          Si vous ne satisfaites pas toutes ces exigences, vous ne devez pas
          accéder au site Web ni l’utiliser.
        </p>

        <ol start={2} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Modifications des modalités et du site Web
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Nous nous réservons le droit, à notre entière discrétion, de réviser
          et de mettre à jour les présentes modalités de temps à autre. Toutes
          ces modifications entrent en vigueur dès leur publication et
          s’appliquent à tout accès au site Web et à toute utilisation continue
          de celui-ci. La date de la dernière mise à jour des présentes
          modalités est indiquée en haut de cette page. Lorsque la loi l’exige,
          nous vous informerons à l’avance de la mise en œuvre de ces
          modifications (par exemple, en vous informant par courriel) afin que
          vous puissiez les examiner avant leur entrée en vigueur. Vous convenez
          d’examiner périodiquement les présentes modalités afin d’être au
          courant de ces modifications, et votre utilisation continue du site
          Web constitue votre acceptation de celles-ci. Si vous n’acceptez pas
          ces modifications des modalités, vous devez vous abstenir de tout
          autre accès au site Web ou de toute autre utilisation de celui-ci.
        </p>
        <p className="mb-3">
          Nous pouvons modifier ou supprimer tout renseignement ou document sur
          le site Web en tout temps, sans vous en aviser. Nous ne serons pas
          responsables si, pour quelque raison que ce soit, l’intégralité ou une
          partie du site Web est restreinte ou inaccessible à tout moment.
        </p>

        <ol start={3} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Utilisation du site Web, création de compte et sécurité
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          La sécurité de vos renseignements personnels est très importante pour
          nous. Nous utilisons des mesures physiques, électroniques et
          administratives conçues pour protéger vos renseignements personnels
          contre toute perte accidentelle et contre tout accès, toute
          utilisation, toute modification et toute divulgation non autorisés.
        </p>
        <p className="mb-3">
          La sécurité de vos renseignements dépend également de vous. En tant
          qu’« utilisateur » ou « utilisatrice » du site Web, vous êtes
          responsable de votre propre accès au site Web et de votre utilisation
          de celui-ci. Vous devez vous assurer que toute personne qui accède au
          site Web par votre connexion Internet connaît les présentes modalités
          et les respecte.
        </p>
        <p className="mb-3">
          Certains contenus ou certaines zones du site Web peuvent nécessiter
          l’inscription de l’utilisateur ou de l’utilisatrice. Une condition de
          votre utilisation du site Web est que tous les renseignements que vous
          y fournissez doivent être exacts, à jour et complets.
        </p>

        <p className="mb-3">
          Malheureusement, la transmission de renseignements par Internet n’est
          pas totalement sûre. Bien que nous fassions de notre mieux pour
          protéger vos renseignements personnels, nous ne pouvons garantir leur
          sécurité lorsque vous les transmettez sur notre site Web. Toute
          transmission de renseignements personnels se fait à vos risques et
          périls. Nous ne sommes pas responsables du contournement des
          paramètres de confidentialité ou des mesures de sécurité du site Web.
        </p>
        <p className="mb-3">
          La communication de vos coordonnées, de vos renseignements
          d’inscription et de toute autre déclaration que vous faites sur le
          site Web au moyen d’une fonctionnalité (ci-après désignées
          collectivement les « fonctions interactives ») constitue votre
          consentement à toutes les mesures que nous prenons à l’égard de ces
          renseignements conformément à notre Politique de confidentialité.
        </p>

        <p className="mb-3">
          Vous devez considérer comme confidentiel tout nom d’utilisateur, mot
          de passe ou autre élément d’information que vous avez choisi ou qui
          vous a été fourni dans le cadre de nos procédures de sécurité et vous
          ne devez le divulguer à aucune autre personne ou entité. Lorsque vous
          accédez à votre compte à partir d’un ordinateur public ou partagé,
          vous devez faire preuve de prudence afin que d’autres personnes ne
          puissent pas voir ni enregistrer votre mot de passe ou d’autres
          renseignements personnels. Vous comprenez et convenez que si vous
          obtenez un compte, ce compte vous est personnel et vous vous engagez à
          ne pas donner à une autre personne l’accès à ce site Web ou à des
          parties de celui-ci en utilisant votre nom d’utilisateur, votre mot de
          passe ou d’autres renseignements protégés. Vous vous engagez à nous
          aviser immédiatement de tout accès non autorisé à votre nom
          d’utilisateur ou mot de passe ou de toute utilisation non autorisée de
          ceux-ci, ainsi que de toute autre atteinte à la sécurité. Vous
          convenez également de vous déconnecter de votre compte à la fin de
          chaque session. Vous êtes responsable de toute mauvaise utilisation de
          votre mot de passe ou de tout accès non autorisé.
        </p>

        <p className="mb-3">
          Nous nous réservons le droit, en tout temps et le cas échéant, de
          désactiver ou de résilier votre compte, tout nom d’utilisateur, mot de
          passe ou autre identificateur, que vous l’ayez choisi ou que nous
          l’ayons fourni, à notre entière discrétion, pour quelque raison que ce
          soit ou sans raison, notamment en cas de violation d’une disposition
          des présentes modalités.
        </p>

        <p className="mb-3">
          Il vous est interdit de tenter de contourner et de violer la sécurité
          du site Web, notamment en faisant ce qui suit :
        </p>

        <p className="mb-1">
          1) accéder à du contenu et des données qui ne vous sont pas destinés;
        </p>

        <p className="mb-1">
          2) violer ou tenter de violer de façon non autorisée les mesures de
          sécurité ou d’authentification;
        </p>

        <p className="mb-1">
          3) restreindre, interrompre ou désactiver le service aux utilisateurs,
          aux hôtes, aux serveurs ou aux réseaux;
        </p>

        <p className="mb-1">
          4) reproduire illicitement l’en-tête de paquet TCP/IP;
        </p>

        <p className="mb-1">
          5) perturber les services du réseau ou perturber autrement la capacité
          du propriétaire du site Web à surveiller celui-ci;
        </p>

        <p className="mb-1">
          6) utiliser un robot Web, un autre robot ou un autre dispositif
          automatique, processus ou moyen pour accéder au site Web à quelque fin
          que ce soit, y compris la surveillance ou la copie du contenu du site
          Web;
        </p>

        <p className="mb-1">
          7) introduire un virus, un cheval de Troie, un ver informatique, une
          bombe logique ou tout autre programme malveillant ou nuisible sur le
          plan technologique;
        </p>

        <p className="mb-1">
          8) attaquer le site Web en recourant à une attaque par déni de
          service, à une attaque par déni de service distribué, à une
          inondation, à une bombarderie ou à un plantage;
        </p>

        <p className="mb-1">
          9) tenter autrement de nuire au bon fonctionnement du site Web.
        </p>

        <ol start={4} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Droits et titres de propriété intellectuelle
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Vous comprenez et convenez que le site Web et l’ensemble de son
          contenu, de ses caractéristiques et de ses fonctionnalités, y compris,
          mais sans s’y limiter, tous les renseignements, les logiciels, les
          codes, les textes, les affichages, les graphiques, les contenus audio,
          les arrangements et toutes les photographies, les vidéos, les
          conceptions, les présentations et les sélections appartiennent à CFT,
          à ses concédants de licence ou aux autres fournisseurs de ces éléments
          et sont protégés sous toutes leurs formes par les lois sur la
          propriété intellectuelle, y compris notamment en matière de droits
          d’auteur, de marques de commerce, de brevets, de secrets commerciaux
          et de tous autres droits patrimoniaux.
        </p>

        <p className="mb-3">
          Le nom, les marques de commerce et le logo de CFT, ainsi que tous les
          noms, les logos, les noms de produits et de services, les slogans et
          toutes les conceptions et les images connexes sont des marques de
          commerce de CFT, de ses sociétés affiliées ou de ses concédants de
          licence. Vous ne devez pas utiliser ces marques sans l’autorisation
          écrite préalable de CFT. Les autres noms, logos, noms de produits et
          de services, dessins, images et slogans mentionnés ou qui apparaissent
          sur le site Web sont les marques de commerce de leurs propriétaires
          respectifs. L’utilisation d’une telle propriété, sauf si elle est
          expressément autorisée, constitue une violation des droits du
          propriétaire susceptible de violer des lois fédérales ou d’autres
          lois, et pourrait exposer le contrevenant à des poursuites
          judiciaires.
        </p>

        <p className="mb-3">
          Vous ne pouvez utiliser le site Web qu’à des fins personnelles et non
          commerciales. Vous ne devez pas, directement ou indirectement,
          reproduire, compiler pour une base de données interne, distribuer,
          modifier, afficher ou réaliser publiquement, republier, télécharger,
          stocker ou transmettre un quelconque élément de notre site Web, ni
          créer des œuvres dérivées de celui-ci, sous quelque forme ou support
          que ce soit, sous réserve des exceptions suivantes :
        </p>

        <p className="mb-1">
          1) votre ordinateur et votre navigateur peuvent temporairement stocker
          ou mettre en cache des copies des éléments auxquels vous avez accédé
          ou que vous avez consultés;
        </p>
        <p className="mb-1">
          2) vous pouvez imprimer un nombre raisonnable de copies, uniquement à
          des fins personnelles ou éducatives, en conservant les avis de droits
          d’auteur sur celles-ci, et ces copies ne peuvent être utilisées qu’à
          des fins personnelles ou éducatives licites et non commerciales et non
          à des fins de reproduction ultérieure, de publication ou de
          distribution de quelque sorte et sur quelque support que ce soit;
        </p>
        <p className="mb-1">
          3) si des plateformes de médias sociaux sont liées à certains contenus
          de notre site Web, vous pouvez prendre les mesures que notre site Web
          et ces plateformes de médias sociaux de tiers permettent.
        </p>

        <p className="mb-3">
          Les utilisateurs ne sont pas autorisés à modifier des copies des
          éléments du site Web ni à supprimer ou à modifier des avis de droit
          d’auteur, de marques de commerce ou d’autres droits patrimoniaux sur
          des copies d’éléments de ce site. Vous ne devez pas viser de fins
          commerciales en accédant à une quelconque partie du site Web, à un
          service ou à un élément accessibles au moyen du site Web, ou en les
          utilisant.
        </p>

        <p className="mb-3">
          Si vous imprimez, copiez ou téléchargez une quelconque partie de notre
          site Web en violation des présentes modalités, votre droit d’utiliser
          le site Web cessera immédiatement et vous devrez, à notre choix,
          retourner ou détruire toute copie que vous aurez faite de son contenu.
          Vous n’avez aucun droit, titre ou intérêt dans ou sur le site Web ou
          tout contenu du site Web, et tous les droits non expressément accordés
          sont réservés par CFT. Toute utilisation du site Web non expressément
          autorisée par les présentes modalités constitue une violation de ces
          modalités et pourrait enfreindre ou violer les lois en matière de
          droits d’auteur, de marques de commerce et d’autres lois sur la
          propriété intellectuelle ou les autres droits patrimoniaux.
        </p>
        <ol start={5} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Conditions d’utilisation et normes relatives au contenu du site
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Vous pouvez accéder au site Web et l’utiliser à condition d’accepter
          de l’utiliser uniquement à des fins licites et conformément aux
          présentes modalités.
        </p>
        <p className="mb-3">
          Sans limiter la portée de ce qui précède, vous garantissez et convenez
          que votre utilisation du site Web ne doit pas :
        </p>
        <ol>
          <li>
            <p className="mb-3">
              Enfreindre, de quelque manière que ce soit, toute loi ou tout
              règlement d’ordre fédéral, provincial, local ou international
              applicable, y compris notamment toute loi concernant l’exportation
              de données ou de logiciels, les brevets, les marques de commerce,
              les secrets commerciaux, les droits d’auteur ou d’autres droits de
              propriété intellectuelle ou légaux (dont les droits de publicité
              et à la protection de la vie privée d’autrui) ou contenir tout
              élément qui pourrait engager une responsabilité civile ou
              criminelle en vertu des lois ou des règlements applicables ou qui
              pourrait autrement être en conflit avec les présentes modalités ou
              notre Politique de confidentialité.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Enfreindre de quelque manière que ce soit les conditions
              d’utilisation de tout site Web tiers lié à notre site Web, y
              compris notamment tout site Web tiers de médias sociaux.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Comprendre ou contenir tout élément constituant une exploitation
              ou qui est obscène, nuisible, menaçant, abusif, harcelant,
              haineux, diffamatoire, sexuellement explicite ou pornographique,
              violent, incendiaire, constituant une discrimination fondée sur la
              race, le sexe, la religion, la nationalité, le handicap,
              l’orientation sexuelle, l’âge ou tout autre motif illicite, ou qui
              est autrement contestable, cette détermination étant à l’entière
              discrétion de CFT.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Impliquer de la traque, une tentative d’exploiter une personne (y
              compris des mineurs) ou de lui nuire de quelque façon que ce soit
              en l’exposant à du contenu inapproprié ou autrement, ou en
              demandant des renseignements personnels comme l’interdisent les
              lois, les règlements ou les codes applicables.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Utiliser, fournir ou participer à fournir des renseignements faux,
              inexacts ou trompeurs.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Comprendre l’envoi, la réception en connaissance de cause, le
              téléversement, le téléchargement, l’utilisation ou la
              réutilisation de tout élément non conforme aux présentes
              modalités.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Encourager toute autre conduite qui restreint ou empêche
              l’utilisation ou la jouissance du site Web par quiconque, ou qui,
              selon nous, peut nuire à CFT ou aux utilisateurs du site Web ou
              engager leur responsabilité.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Causer de la gêne, des inconvénients ou de l’anxiété inutile ou
              être susceptible de contrarier, d’embarrasser ou d’inquiéter une
              autre personne.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Promouvoir toute activité illégale ou encourager, promouvoir ou
              aider tout acte illégal.
            </p>
          </li>
        </ol>
        <ol start={6} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Surveillance du site et mesures d’application de la loi, de
              suspension et de résiliation
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">CFT a le droit, sans préavis, de :</p>
        <ul>
          <li>
            <p className="mb-3">
              Prendre les mesures juridiques appropriées, y compris notamment le
              renvoi aux organismes d’application de la loi ou de
              réglementation, ou aviser la partie lésée de toute utilisation
              illégale ou non autorisée du site Web. Sans limiter la portée de
              ce qui précède, nous avons le droit de coopérer pleinement avec
              les autorités chargées de l’application des lois ou les tribunaux
              qui nous demandent ou nous ordonnent de divulguer l’identité ou
              d’autres renseignements de toute personne qui accède au contenu du
              site Web ou qui en demande des éléments.
            </p>
          </li>
          <li>
            <p className="mb-3">
              Résilier ou de suspendre votre accès à l’intégralité ou à toute
              partie du site Web pour une raison quelconque ou sans raison,
              notamment en cas de violation des présentes modalités.
            </p>
          </li>
        </ul>
        <p className="mb-3">
          VOUS DÉGAGEZ DE TOUTE RESPONSABILITÉ CFT ET SA SOCIÉTÉ MÈRE, SES
          FILIALES ET SES SOCIÉTÉS AFFILIÉES, AINSI QUE LEURS ADMINISTRATEURS,
          DIRIGEANTS, EMPLOYÉS, MANDATAIRES, FOURNISSEURS DE SERVICES,
          ENTREPRENEURS, CONCÉDANTS DE LICENCE, TITULAIRES DE LICENCE,
          FOURNISSEURS ET AYANTS DROIT À L’ÉGARD DE TOUTE RÉCLAMATION DÉCOULANT
          DE TOUTE ACTION PRISE PAR CFT OU L’UNE DES PARTIES SUSMENTIONNÉES
          CONCERNANT TOUTE ENQUÊTE MENÉE PAR CFT OU CES PARTIES OU PAR LES
          AUTORITÉS CHARGÉES DE L’APPLICATION DES LOIS.
        </p>
        <p className="mb-3">
          Nous n’avons aucune obligation ni aucune responsabilité envers quelque
          partie que ce soit de surveiller le site Web ou son utilisation.
        </p>
        <ol start={7}>
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Aucune dépendance
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Le contenu de notre site Web est fourni à titre d’information
          seulement. Il ne s’agit pas de conseils sur la foi desquels vous
          devriez dépendre. Vous devez obtenir des conseils plus précis ou
          professionnels avant de prendre, ou de vous abstenir de prendre, toute
          action sur la base du contenu de notre site.
        </p>
        <p className="mb-3">
          Bien que nous fassions des efforts raisonnables pour mettre à jour les
          renseignements sur notre site Web, nous ne faisons aucune déclaration
          ni ne donnons aucune garantie, de façon expresse ou implicite, voulant
          que le contenu de notre site Web soit exact, complet ou à jour. Vous
          utilisez le site Web à vos propres risques et CFT n’est aucunement
          responsable de votre utilisation du site.
        </p>
        <p className="mb-3">
          Le site Web peut comprendre du contenu fourni par des tiers, notamment
          d’autres utilisateurs et des concédants de licence tiers. Toutes les
          déclarations et les opinions exprimées dans un tel contenu de tiers,
          étranger au contenu fourni par CFT, sont uniquement les opinions et la
          responsabilité de la personne ou de l’entité fournissant ces éléments.
          Ces éléments ne reflètent pas nécessairement l’opinion de CFT. CFT n’a
          aucune responsabilité envers vous, ou envers un tiers, en ce qui
          concerne le contenu ou l’exactitude d’un quelconque élément de tiers.
        </p>
        <ol start={8} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Confidentialité
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          En soumettant vos renseignements personnels et en utilisant notre site
          Web, vous consentez à la collecte, à l’utilisation, à la reproduction,
          à l’hébergement, à la transmission et à la communication de tout
          contenu d’utilisateur soumis conformément à notre Politique de
          confidentialité, selon ce que nous estimons nécessaire à l’utilisation
          du site Web.
        </p>
        <p className="mb-3">
          En utilisant ce site Web, vous consentez à l’utilisation de témoins
          qui permettent à un serveur de récupérer des demandes ou des
          inscriptions antérieures et des adresses IP pour analyser les
          habitudes d’utilisation du site Web. Vous pouvez configurer votre
          navigateur pour vous aviser avant de recevoir un témoin, afin de
          pouvoir décider si vous l’acceptez ou non. Vous pouvez également
          configurer votre navigateur pour refuser les témoins. Toutefois, si
          vous le faites, certaines parties du site Web pourraient ne pas
          fonctionner adéquatement. Pour de plus amples renseignements sur ces
          pratiques automatisées de collecte de renseignements, consultez notre
          Politique de confidentialité.
        </p>
        <ol start={9} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Sites Web tiers
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Pour votre commodité, ce site Web peut fournir des liens ou des
          pointeurs vers des sites de tiers. Nous ne faisons aucune déclaration
          au sujet d’autres sites Web accessibles à partir de notre site Web. Si
          vous choisissez d’accéder à de tels sites, vous le faites à vos
          risques. Nous n’avons aucun contrôle sur le contenu de ces sites tiers
          et n’acceptons aucune responsabilité pour ces sites ou pour toute
          perte ou tout dommage pouvant découler de votre utilisation de ces
          sites. Vous êtes soumis aux conditions d’utilisation de ces sites
          tiers.
        </p>
        <p className="mb-3">
          Ces liens vers des sites tiers à partir de notre site Web peuvent
          comprendre des liens vers certaines fonctionnalités de médias sociaux
          vous permettant d’établir des liens avec certains éléments du site
          Web, ou de les transmettre vous-même ou par l’intermédiaire de sites
          Web tiers. Vous ne pouvez utiliser ces fonctionnalités que lorsque
          nous vous les avons fournies, et uniquement en ce qui concerne le
          contenu désigné.
        </p>
        <p className="mb-3">
          Vous pouvez créer un lien vers notre page d’accueil, à condition que
          ce soit d’une manière équitable et légale, sans porter atteinte à
          notre réputation ni en tirer parti. Vous ne devez pas établir un lien
          de manière à suggérer une forme quelconque d’association,
          d’approbation ou d’appui de notre part lorsqu’il n’y en a pas. Notre
          site Web ne doit pas être cadré sur un autre site, et vous n’avez pas
          l’autorisation de créer de lien vers une autre partie de notre site
          Web que la page d’accueil. Nous nous réservons le droit de retirer
          notre permission d’établir des liens sans préavis. Le site Web dans
          lequel vous créez un lien doit respecter à tous égards les
          dispositions relatives aux conditions d’utilisation et aux normes
          relatives au contenu du site des présentes modalités. Vous acceptez de
          collaborer avec nous pour faire cesser immédiatement tout cadrage non
          autorisé ou toute liaison non autorisée.
        </p>
        <ol start={10} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Restrictions géographiques
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Le propriétaire du site Web est situé en Ontario, au Canada. Nous
          fournissons ce site Web pour l’utilisation exclusive des personnes
          situées au Canada. Le site Web n’est pas destiné à être utilisé dans
          un territoire où son utilisation est interdite. Si vous accédez au
          site Web depuis l’extérieur du Canada, vous le faites à vos risques et
          périls et êtes responsable du respect des lois locales de votre
          territoire.
        </p>
        <ol start={11}>
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Avertissement d’exonération de garanties
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          VOUS COMPRENEZ ET CONVENEZ QUE VOTRE UTILISATION DU SITE WEB, DE SON
          CONTENU ET DE TOUT SERVICE OU ÉLÉMENT TROUVÉ OU OBTENU PAR LE BIAIS DU
          SITE WEB SE FAIT À VOS RISQUES ET PÉRILS. LE SITE WEB, SON CONTENU ET
          TOUT SERVICE OU ÉLÉMENT TROUVÉ OU OBTENU PAR SON INTERMÉDIAIRE SONT
          FOURNIS « TELS QUELS » ET « SELON LA DISPONIBILITÉ », SANS GARANTIE NI
          CONDITION DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE, Y
          COMPRIS, MAIS SANS S’Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ
          MARCHANDE, D’ADAPTATION À UN USAGE PARTICULIER OU D’ABSENCE DE
          CONTREFAÇON. CE QUI PRÉCÈDE N’A AUCUNE INCIDENCE SUR LES GARANTIES QUI
          NE PEUVENT PAS ÊTRE EXCLUES OU LIMITÉES EN VERTU DES LOIS APPLICABLES.
        </p>
        <p className="mb-3">
          NI CFT, NI SA SOCIÉTÉ MÈRE, SES FILIALES OU SES SOCIÉTÉS AFFILIÉES, NI
          LEURS ADMINISTRATEURS, DIRIGEANTS, EMPLOYÉS, MANDATAIRES, FOURNISSEURS
          DE SERVICES, ENTREPRENEURS, CONCÉDANTS DE LICENCE, TITULAIRES DE
          LICENCE, FOURNISSEURS OU AYANTS DROIT N’OFFRENT DE GARANTIE, NE FONT
          DE DÉCLARATION OU N’ACCORDENT D’APPROBATION QUANT À L’EXHAUSTIVITÉ, LA
          SÉCURITÉ, LA FIABILITÉ, LA PERTINENCE, L’EXACTITUDE, L’ACTUALITÉ OU LA
          DISPONIBILITÉ DU SITE WEB OU DE SON CONTENU. SANS LIMITER LA PORTÉE DE
          CE QUI PRÉCÈDE, NI CFT, NI SA SOCIÉTÉ MÈRE, SES FILIALES OU SES
          SOCIÉTÉS AFFILIÉES, NI LEURS ADMINISTRATEURS, DIRIGEANTS, EMPLOYÉS,
          MANDATAIRES, FOURNISSEURS DE SERVICES, ENTREPRENEURS, CONCÉDANTS DE
          LICENCE, TITULAIRES DE LICENCE, FOURNISSEURS OU AYANTS DROIT NE
          DÉCLARENT OU NE GARANTISSENT QUE LE SITE WEB, SON CONTENU OU TOUT
          SERVICE OU ÉLÉMENT TROUVÉ OU OBTENU PAR LE BIAIS DU SITE WEB, SONT
          EXACTS, FIABLES, EXEMPTS D’ERREURS OU ININTERROMPUS, QUE LES DÉFAUTS
          SERONT CORRIGÉS ET QUE NOTRE SITE WEB OU LE SERVEUR QUI LE REND
          DISPONIBLE SONT EXEMPTS DE VIRUS OU D’AUTRES ÉLÉMENTS DOMMAGEABLES.
        </p>
        <p className="mb-3">
          NOUS NE POUVONS GARANTIR ET NOUS NE GARANTISSONS PAS QUE LES FICHIERS
          OU LES DONNÉES MIS À DISPOSITION POUR TÉLÉCHARGEMENT DEPUIS INTERNET
          OU LE SITE WEB SERONT EXEMPTS DE VIRUS OU D’AUTRES CODES DESTRUCTEURS.
          VOUS ÊTES ENTIÈREMENT RESPONSABLE DE VOTRE UTILISATION DU SITE WEB, DE
          VOTRE ORDINATEUR, D’INTERNET ET DE LA SÉCURITÉ DES DONNÉES. DANS LA
          PLEINE MESURE PERMISE PAR LA LOI, NOUS NE SOMMES PAS RESPONSABLES DES
          PERTES OU DES DOMMAGES CAUSÉS PAR UNE ATTAQUE PAR DÉNI DE SERVICE, UNE
          ATTAQUE PAR DÉNI DE SERVICE DISTRIBUÉ, UNE SURCHARGE, UNE INONDATION,
          UNE BOMBARDERIE, UN PLANTAGE, DES VIRUS, DES CHEVAUX DE TROIE, DES
          VERS INFORMATIQUES, DES BOMBES LOGIQUES OU TOUT AUTRE PROGRAMME
          NUISIBLE SUR LE PLAN TECHNOLOGIQUE POUVANT INFECTER VOTRE MATÉRIEL
          INFORMATIQUE, VOS PROGRAMMES, VOS DONNÉES OU TOUT AUTRE MATÉRIEL VOUS
          APPARTENANT EN RAISON DE VOTRE UTILISATION DU SITE WEB OU DE TOUT
          SERVICE OU ÉLÉMENT TROUVÉ OU OBTENU PAR LE BIAIS DU SITE WEB OU DE
          VOTRE TÉLÉCHARGEMENT DE TOUT ÉLÉMENT AFFICHÉ SUR CELUI-CI, OU SUR TOUT
          SITE WEB LIÉ À CELUI-CI.
        </p>
        <ol start={12} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Limitation de responsabilité
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          SAUF LORSQUE DE TELLES EXCLUSIONS SONT INTERDITES PAR LA LOI, EN AUCUN
          CAS CFT OU SA SOCIÉTÉ MÈRE, SES FILIALES, SES SOCIÉTÉS AFFILIÉES OU
          LEURS ADMINISTRATEURS, DIRIGEANTS, EMPLOYÉS, MANDATAIRES, FOURNISSEURS
          DE SERVICES, ENTREPRENEURS, CONCÉDANTS DE LICENCE, TITULAIRES DE
          LICENCE, FOURNISSEURS OU AYANTS DROIT NE SONT RESPONSABLES DE
          NÉGLIGENCE, DE NÉGLIGENCE GRAVE, D’ASSERTION NÉGLIGENTE ET INEXACTE,
          DE VIOLATION FONDAMENTALE OU DE DOMMAGES DE QUELQUE SORTE QUE CE SOIT,
          AU TITRE DE QUELQUE THÉORIE JURIDIQUE QUE CE SOIT, Y COMPRIS LES
          DOMMAGES DIRECTS, INDIRECTS, PARTICULIERS, ACCESSOIRES, CONSÉCUTIFS OU
          PUNITIFS, Y COMPRIS, MAIS SANS S’Y LIMITER, LES PRÉJUDICES CORPORELS,
          LA DOULEUR ET LA SOUFFRANCE, LA DÉTRESSE ÉMOTIONNELLE, LA PERTE DE
          REVENUS, LA PERTE DE PROFITS, LA PERTE D’AFFAIRES OU D’ÉCONOMIES
          ANTICIPÉES, LA PERTE D’UTILISATION, LA PERTE DE CLIENTÈLE, LA PERTE DE
          DONNÉES, ET SI ELLE EST CAUSÉE PAR UN DÉLIT (DONT LA NÉGLIGENCE), UNE
          VIOLATION DE CONTRAT, UNE ATTEINTE À LA VIE PRIVÉE, OU AUTREMENT, MÊME
          SI LA PARTIE EST PRÉSUMÉE AVOIR ÉTÉ AVISÉE OU AVAIT DES RAISONS DE LE
          SAVOIR, EN LIEN AVEC OU DÉCOULANT DE VOTRE UTILISATION DU SITE WEB, DE
          TOUT SITE WEB LIÉ OU DE TOUT AUTRE SITE WEB TIERS, OU DE VOTRE
          DÉPENDANCE ENVERS CEUX-CI OU DE VOTRE INCAPACITÉ À LES UTILISER, OU DE
          TOUT CONTENU, ÉLÉMENT, AFFICHAGE OU INFORMATION D’UN SITE WEB, MÊME SI
          LA PARTIE EST PRÉSUMÉE AVOIR ÉTÉ AVISÉE OU AVAIT DES RAISONS DE LE
          SAVOIR.
        </p>
        <ol start={13} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Indemnisation
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Dans toute la mesure permise par la loi applicable, vous convenez de
          défendre, d’indemniser et d’exonérer de toute responsabilité CFT, sa
          société mère, ses filiales et ses sociétés affiliées, ainsi que leurs
          administrateurs, dirigeants, employés, mandataires, fournisseurs de
          services, entrepreneurs, concédants de licence, fournisseurs, ayants
          droit et cessionnaires à l’égard de toute réclamation, responsabilité,
          décision, perte ou dépense et de tout dommage, jugement, coût ou frais
          (y compris les frais et les débours juridiques raisonnables) découlant
          de votre violation des présentes modalités ou de votre utilisation du
          site Web ou s’y rapportant, ce qui comprend notamment les sites de
          tiers, ainsi que toute utilisation du contenu, des services ou des
          produits du site Web non expressément autorisée dans les présentes
          modalités.
        </p>
        <ol start={14} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Droit applicable et élection de for
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Le site Web et les présentes modalités sont régis par les lois de la
          province de l’Ontario et les lois fédérales du Canada applicables et
          seront interprétés conformément à celles-ci, sans donner effet à une
          disposition, à un principe ou à une règle en matière désignation du
          droit applicable ou de conflits de lois (qu’il s’agisse des lois de la
          province de l’Ontario ou de tout autre territoire) et nonobstant votre
          domicile, votre résidence ou votre emplacement réel.
        </p>
        <p className="mb-3">
          Toute action ou procédure se rapportant au site Web et au titre des
          présentes modalités sera intentée devant les tribunaux de la province
          de l’Ontario ou de la Cour fédérale du Canada, et chaque partie se
          soumet irrévocablement à la compétence exclusive de ces tribunaux dans
          une telle action ou procédure. Vous renoncez à toute objection de
          votre part à la compétence de ces tribunaux et au lieu de leur
          exercice.
        </p>
        <ol start={15} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Renonciation
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Le défaut ou le retard de l’exercice d’un droit, d’un recours, d’un
          pouvoir ou d’un privilège relevant des présentes modalités ne
          constitue pas une renonciation à celui-ci et ne saurait être
          interprété comme tel. L’exercice unique ou partiel d’un droit, d’un
          recours, d’un pouvoir ou d’un privilège au titre des présentes
          n’interdit pas l’exercice ultérieur de ce droit ou de tout autre
          droit, recours, pouvoir ou privilège.
        </p>
        <ol start={16} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Divisibilité
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Si l’une des dispositions des présentes modalités est invalide,
          illégale ou inapplicable dans un territoire quelconque, cette nullité,
          cette illégalité ou cette inapplicabilité n’affectera aucune autre
          disposition des présentes modalités et n’invalidera pas ou ne rendra
          pas inapplicable cette disposition dans un autre territoire.
        </p>
        <ol start={17} className="list-disc">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Intégralité de l’entente
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Les présentes modalités et notre Politique de confidentialité
          constituent l’intégralité de la seule entente conclue entre CFT et
          vous quant au site Web et elles annulent et remplacent toutes les
          ententes, les déclarations et les garanties et tous les accords
          antérieurs et concomitants, tant écrits qu’oraux, concernant cet
          objet.
        </p>
        <ol start={18} className="list-disk">
          <li>
            <Typography.Heading size="sm" className="mb-3">
              Signalements et contact
            </Typography.Heading>
          </li>
        </ol>
        <p className="mb-3">
          Veuillez nous faire parvenir tout signalement d’utilisation abusive
          potentielle, toute demande ou tout commentaire concernant le site Web
          à l’adresse courriel :{" "}
          <a href="mailto:admin@canadasforesttrust.ca">
            <strong>admin@canadasforesttrust.ca</strong>
          </a>
          .
        </p>
      </div>
    );
  }
  return (
    <div className="max-w-3xl mx-auto p-5">
      <p className="mb-3">
        <Typography.Heading size="lg" className="mb-5">
          Canada’s Forest Trust Corporation
        </Typography.Heading>
      </p>
      <p className="mb-3">
        <Typography.Heading size="md" className="mb-3">
          “Portal” Terms and Conditions of Use
        </Typography.Heading>
      </p>
      <p className="mb-3">
        THESE TERMS TAKE EFFECT UPON ACCESS TO THE PORTAL. BY ACCESSING THE
        PORTAL YOU: (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE
        TERMS; (B)&nbsp;REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER,
        AND AUTHORITY TO ENTER INTO THESE TERMS; AND, IF YOU ARE ENTERING INTO
        THESE TERMS ON BEHALF OF AN ORGANIZATION, THAT YOU ARE DULY AUTHORIZED
        TO BIND THAT ORGANIZATION TO THESE TERMS; AND (C) ACCEPT THESE TERMS AND
        AGREE THAT YOU ARE LEGALLY BOUND BY THEM.
      </p>
      <p className="mb-4">
        IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT OTHERWISE ACCESS OR
        USE THE PORTAL.
      </p>
      <ol className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Acceptance of Terms
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-4">
        These terms and conditions of use for the Canada’s Forest Trust
        Corporation Forest Portal (the “Portal”), constitute a legal agreement
        and are entered into by and between you and Canada’s Forest Trust
        Corporation (the “<strong>CFTC</strong>,” “<strong>we</strong>,” “
        <strong>us</strong>,” “<strong>our</strong>”). The following terms and
        conditions, together with our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>{" "}
        (collectively, these “<strong>Terms</strong>”), govern your access to
        and use of the Portal.
      </p>
      <ol start={2} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Your Use of the Portal and Account Set-Up and Security
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The security of your personal information is very important to us. We
        use physical, electronic, and administrative measures designed to secure
        your personal information from accidental loss and from unauthorized
        access, use, alteration, and disclosure.
      </p>
      <p className="mb-3">
        The safety and security of your information also depends on you. As a “
        <strong>User</strong>” of the Portal, you are responsible for your own
        access to and use of the Portal. You are required to ensure that anyone
        who accesses the Portal through your internet connection is aware of
        these Terms and complies with them.
      </p>
      <p className="mb-3">
        Certain content or areas on the Portal may require User registration. It
        is a condition of your use of the Portal that all the information you
        provide on the Portal is correct, current, and complete.
      </p>
      <p className="mb-3">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we do our best to protect your personal
        information, we cannot guarantee the security of your personal
        information transmitted to our Portal. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Portal.
      </p>
      <p className="mb-3">
        Your provision of contact information, registration information and any
        other submissions you make to the Portal through any functionality
        (collectively, “<strong>Interactive Functions</strong>”) constitutes
        your consent to all actions we take with respect to such information
        consistent with our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <p className="mb-3">
        Any username, password, or any other piece of information chosen by you,
        or provided to you as part of our security procedures, must be treated
        as confidential, and you must not disclose it to any other person or
        entity. You must exercise caution when accessing your account from a
        public or shared computer so that others are not able to view or record
        your password or other personal information. You understand and agree
        that should you be provided an account, your account is personal to you
        and you agree not to provide any other person with access to this Portal
        or portions of it using your username, password, or other security
        information. You agree to notify us immediately of any unauthorized
        access to or use of your username or password or any other breach of
        security. You also agree to ensure that you logout from your account at
        the end of each session. You are responsible for any password misuse or
        any unauthorized access.
      </p>
      <p className="mb-3">
        We reserve the right at any time and from time to time, to disable or
        terminate your account, any username, password, or other identifier,
        whether chosen by you or provided by us, in our sole discretion for any
        or no reason, including any violation of any provision of these Terms.
      </p>
      <p className="mb-3">
        You are prohibited from attempting to circumvent and from violating the
        security of this Portal, including, without limitation: (a) accessing
        content and data that is not intended for you; (b) attempting to breach
        or breaching the security and/or authentication measures which are not
        authorized; (c) restricting, disrupting or disabling service to users,
        hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet
        header; (e) disrupting network services and otherwise disrupting Portal
        owner’s ability to monitor the Portal; (f) using any robot, spider, or
        other automatic device, process, or means to access the Portal for any
        purpose, including monitoring or copying any of the material on the
        Portal; (g) introducing any viruses, trojan horses, worms, logic bombs,
        or other material that is malicious or technologically harmful; (h)
        attacking the Portal via a denial-of-service attack, distributed
        denial-of-service attack, flooding, mailbombing, or crashing; and (i)
        otherwise attempting to interfere with the proper working of the Portal.
      </p>
      <ol start={3} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Intellectual Property Rights and Ownership
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        You understand and agree that the Portal and its entire contents,
        features, and functionality, including, but not limited to, all
        information, software, code, text, displays, graphics, photographs,
        video, audio, design, presentation, selection, and arrangement, are
        owned by the CFTC, its licensors, or other providers of such material
        and are protected in all forms by intellectual property laws including
        without limitation, copyright, trademark, patent, trade secret, and any
        other proprietary rights.
      </p>
      <p className="mb-3">
        The CFTC name, trademarks, logo, and all related names, logos, product
        and service names, designs, images, and slogans are trademarks of the
        CFTC or its affiliates or licensors. You must not use such marks without
        the prior written permission of the CFTC. Other names, logos, product
        and service names, designs, images, and slogans mentioned, or which
        appear on this Portal are the trademarks of their respective owners. Use
        of any such property, except as expressly authorized, shall constitute
        an infringement or violation of the rights of the property owner and may
        be a violation of federal or other laws and could subject the infringer
        to legal action.
      </p>
      <p className="mb-3">
        You may only use the Portal for your personal and non-commercial use.
        You shall not directly or indirectly reproduce, compile for an internal
        database, distribute, modify, create derivative works of, publicly
        display, publicly perform, republish, download, store, or transmit any
        of the material on our Portal, in any form or medium whatsoever except:
      </p>
      <ol>
        <li>
          <p className="mb-3">
            your computer and browser may temporarily store or cache copies of
            materials being accessed and viewed;
          </p>
        </li>
        <li>
          <p className="mb-3">
            a reasonable number of copies for personal use or educational use
            only may be printed keeping any proprietary notices thereon, which
            may only be used for non-commercial and lawful personal or
            educational use and not for further reproduction, publication, or
            distribution of any kind on any medium whatsoever;
          </p>
        </li>
        <li>
          <p className="mb-3">
            in the event social media platforms are linked to certain content on
            our Portal, you may take such actions as our Portal and such
            third-party social media platforms permit.
          </p>
        </li>
      </ol>
      <p className="mb-3">
        Users are not permitted to modify copies of any materials from this
        Portal nor delete or alter any copyright, trademark, or other
        proprietary rights notices from copies of materials from this site. You
        must not access or use for any commercial purposes any part of the
        Portal or any services or materials available through the Portal.
      </p>
      <p className="mb-3">
        If you print off, copy or download any part of our Portal in breach of
        these Terms, your right to use the Portal will cease immediately and you
        must, at our option, return or destroy any copies of the materials you
        have made. You have no right, title, or interest in or to the Portal or
        to any content on the Portal, and all rights not expressly granted are
        reserved by the CFTC. Any use of the Portal not expressly permitted by
        these Terms is a breach of these Terms and may infringe or violate
        copyright, trademark, and other intellectual property or other
        proprietary laws.
      </p>
      <ol start={4} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Greenhouse Gas Offset Credits
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        If the federal, provincial, and/or territorial governments release
        legislation that allows for the creation of regulatory greenhouse gas
        emission offsets resulting from this Agreement (&quot;
        <strong>Regulatory Offsets</strong>&quot;), You acknowledge and agree
        that you will not seek to create Regulatory Offsets if and to the extent
        that voluntary greenhouse gas emission offsets have already been
        created, used, advertised and/or retired.
      </p>
      <ol start={5} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Conditions of Use and Portal Content Standards
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        As a condition of your access and use, you agree that you may use the
        Portal only for lawful purposes and in accordance with these Terms.
      </p>
      <p className="mb-3">
        Without limiting the foregoing, you warrant and agree that your use of
        the Portal shall not:
      </p>
      <ol>
        <li>
          <p className="mb-3">
            In any manner violate any applicable federal, provincial, local, or
            international law or regulation including, without limitation, any
            laws regarding the export of data or software, patent, trademark,
            trade secret, copyright, or other intellectual property, legal
            rights (including the rights of publicity and privacy of others) or
            contain any material that could give rise to any civil or criminal
            liability under applicable laws or regulations or that otherwise may
            be in conflict with these Terms and our{" "}
            <a href="https://www.canadasforesttrust.ca/privacy-policy">
              Privacy Policy
            </a>
            .
          </p>
        </li>
        <li>
          <p className="mb-3">
            In any manner violate the terms of use of any third-party website
            that is linked to the Portal, including but not limited to, any
            third-party social media website.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Include or contain any material that is exploitive, obscene,
            harmful, threatening, abusive, harassing, hateful, defamatory,
            sexually explicit or pornographic, violent, inflammatory, or
            discriminatory based on race, sex, religion, nationality,
            disability, sexual orientation, or age or other such legally
            prohibited ground or be otherwise objectionable, such determination
            to be made in CFTC’s sole discretion.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Involve stalking, attempting to exploit or harm any individual
            (including minors) in any way by exposing them to inappropriate
            content or otherwise or ask for personal information as prohibited
            under applicable laws, regulations, or code.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Involve, provide, or contribute any false, inaccurate, or misleading
            information.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Include sending, knowingly receiving, uploading, downloading, using,
            or reusing any material that does not comply with these Terms.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Encourage any other conduct that restricts or inhibits anyone’s use
            or enjoyment of the Portal, or which, as determined by us, may harm
            the CFTC or users of the Portal or expose them to liability.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, or alarm any other person.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </p>
        </li>
      </ol>
      <ol start={6} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Portal Monitoring and Enforcement, Suspension and Termination
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        CFTC has the right, without provision of notice to:
      </p>
      <ul>
        <li>
          <p className="mb-3">
            Take appropriate legal action, including, without limitation,
            referral to law enforcement or regulatory authority, or notifying
            the harmed party of any illegal or unauthorized use of the Portal.
            Without limiting the foregoing, we have the right to fully cooperate
            with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone
            accessing or requesting any materials on or through the Portal.
          </p>
        </li>
        <li>
          <p className="mb-3">
            Terminate or suspend your access to all or part of the Portal for
            any or no reason, including, without limitation, any violation of
            these Terms.
          </p>
        </li>
      </ul>
      <p className="mb-3">
        YOU WAIVE AND HOLD HARMLESS THE CFTC AND ITS PARENT, SUBSIDIARIES,
        AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
        SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND
        SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY
        CFTC AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY
        EITHER CFTC OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
      </p>
      <p className="mb-3">
        We have no obligation, nor any responsibility to any party to monitor
        the Portal or its use.
      </p>
      <ol start={7}>
        <li>
          <Typography.Heading size="sm" className="mb-3">
            No Reliance
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The content on our Portal is provided for general information purposes
        only and content relating to your Smart ForestTM may contain estimations
        that are subject to variation based on a number of environmental
        factors, including but not limited to: fire, drought, flooding, severe
        weather conditions, climate change, timber diseases and insect
        infestations.
      </p>
      <p className="mb-3">
        Although we make reasonable efforts to update the information on our
        Portal and validate the information relating to your Smart ForestTM, we
        make no representations, warranties, or guarantees, whether express or
        implied, that the content is accurate, complete, or up to date.
      </p>
      <p className="mb-3">
        This Portal may include content provided by third parties, including
        from other users and third-party licensors. All statements and/or
        opinions expressed in any such third-party content, other than the
        content provided by CFTC, are solely the opinions and the responsibility
        of the person or entity providing those materials. Such materials do not
        necessarily reflect the opinion of CFTC. CFTC has no responsibility or
        liability whatsoever to you, or any third party, for the content or
        accuracy of any third-party materials.
      </p>
      <ol start={8} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Privacy
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        By submitting your personal information and using our Portal, you
        consent to the collection, use, reproduction, hosting, transmission, and
        disclosure of any such user content submissions in compliance with our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>
        , as we deem necessary for use of the Portal.
      </p>
      <p className="mb-3">
        By using this Portal you are consenting to the use of cookies which
        allow a server to recall previous requests or registration and/or IP
        addresses to analyze website use patterns. You can set your browser to
        notify you before you receive a cookie, giving you the chance to decide
        whether to accept it. You can also set your browser to turn off cookies.
        If you do, however, some areas of the Portal may not function
        adequately. For more information on this automated information gathering
        practices, see our{" "}
        <a href="https://www.canadasforesttrust.ca/privacy-policy">
          Privacy Policy
        </a>
        .
      </p>
      <ol start={9} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Third-Party Websites
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        For your convenience, this Portal may provide links or pointers to
        third-party sites. We make no representations about any other websites
        that may be accessed from this Portal. If you choose to access any such
        sites, you do so at your own risk. We have no control over the contents
        of any such third-party sites and accept no responsibility for such
        sites or for any loss or damage that may arise from your use of them.
        You are subject to any terms and conditions of such third-party sites.
      </p>
      <p className="mb-3">
        Such links to third-party sites from the Portal may include links to
        certain social media features that enable you to link or transmit on
        your own or using certain third-party websites, certain content from
        this Portal. You may only use these features when they are provided by
        us and solely with respect to the content identified.
      </p>
      <p className="mb-3">
        You may link to our homepage, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
        You must not establish a link in such a way as to suggest any form of
        association, approval, or endorsement on our part where none exists. Our
        Portal must not be framed on any other site, nor may you create a link
        to any part of our Portal other than the homepage. We reserve the right
        to withdraw linking permission without notice. The website in which you
        are linking must comply in all respects with the provisions regarding
        Conditions of Use and Site Content Standards in these Terms. You agree
        to cooperate with us in causing any unauthorized framing or linking to
        immediately stop.
      </p>
      <ol start={10} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Geographic Restrictions
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The owner of the Portal is based in the Province of Ontario in Canada.
        We provide this Portal for use only by persons located in Canada. This
        Portal is not intended for use in any jurisdiction where its use is not
        permitted. If you access the Portal from outside Canada, you do so at
        your own risk and you are responsible for compliance with local laws of
        your jurisdiction.
      </p>
      <ol start={11}>
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Disclaimer of Warranties
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE PORTAL, ITS CONTENT, AND
        ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE PORTAL IS AT YOUR
        OWN RISK. THE PORTAL, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR
        ATTAINED THROUGH THE PORTAL ARE PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND,
        EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, MERCHANTABLE QUALITY, FITNESS FOR A
        PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT
        ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </p>
      <p className="mb-3">
        NEITHER CFTC NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
        CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY
        WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE
        COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR
        AVAILABILITY OF THE PORTAL OR ITS CONTENTS. WITHOUT LIMITING THE
        FOREGOING, NEITHER CFTC NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR
        THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
        PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
        REPRESENT OR WARRANT THAT THE PORTAL, ITS CONTENT, OR ANY SERVICES OR
        ITEMS FOUND OR ATTAINED THROUGH THE PORTAL WILL BE ACCURATE, RELIABLE,
        ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
        PORTAL OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
        OTHER HARMFUL COMPONENTS.
      </p>
      <p className="mb-3">
        WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
        FOR DOWNLOADING FROM THE INTERNET OR THE PORTAL WILL BE FREE OF VIRUSES
        OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR
        YOUR USE OF THE PORTAL AND YOUR COMPUTER, INTERNET, AND DATA SECURITY.
        TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
        LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED
        DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR
        CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER
        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE PORTAL OR ANY SERVICES OR ITEMS FOUND OR ATTAINED
        THROUGH THE PORTAL OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
        OR ON ANY PORTAL LINKED TO IT.
      </p>
      <ol start={12} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Limitation on Liability
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
        CIRCUMSTANCES WILL CFTC OR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
        CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE
        FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION,
        FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
        PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
        AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
        LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
        LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
        OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS
        ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION
        WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE PORTAL, ANY
        LINKED PORTALS OR SUCH OTHER THIRD-PARTY PORTALS, NOR ANY PORTAL
        CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON EVEN IF THE PARTY
        WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
      </p>
      <ol start={13} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Indemnification
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        To the maximum extent permitted by applicable law, you agree to defend,
        indemnify, and hold harmless CFTC, its parent, subsidiaries, affiliates,
        and their respective directors, officers, employees, agents, service
        providers, contractors, licensors, suppliers, successors, and assigns
        from and against any claims, liabilities, damages, judgments, awards,
        losses, costs, expenses, or fees (including reasonable legal fees and
        disbursements) arising out of or relating to your breach of these Terms
        or your use of the Portal, including, but not limited to third-party
        sites, any use of the Portal’s content, services, and products other
        than as expressly authorized in these Terms.
      </p>
      <ol start={14} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Governing Law and Choice of Forum
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The Portal and these Terms will be governed by and construed in
        accordance with the laws of the Province of Ontario and the federal laws
        of Canada applicable therein, without giving effect to any choice or
        conflict of law provision, principle, or rule (whether of the laws of
        the Province of Ontario or any other jurisdiction) and notwithstanding
        your domicile, residence, or physical location.
      </p>
      <p className="mb-3">
        Any action or proceeding arising out of or relating to this Portal and
        under these Terms will be instituted in the courts of the Province of
        Ontario and/or the Federal Court of Canada, and each party irrevocably
        submits to the exclusive jurisdiction of such courts in any such action
        or proceeding. You waive any and all objections to the exercise of
        jurisdiction over you by such courts and to the venue of such courts.
      </p>
      <ol start={15} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Waiver
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        No failure to exercise, or delay in exercising, any right, remedy,
        power, or privilege arising from these Terms operates, or may be
        construed, as a waiver thereof. No single or partial exercise of any
        right, remedy, power, or privilege hereunder precludes any other or
        further exercise thereof or the exercise of any other right, remedy,
        power, or privilege.
      </p>
      <ol start={16} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Severability
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        If any term or provision of these Terms is invalid, illegal, or
        unenforceable in any jurisdiction, such invalidity, illegality, or
        unenforceability shall not affect any other term or provision of these
        Terms or invalidate or render unenforceable such term or provision in
        any other jurisdiction.
      </p>
      <ol start={17} className="list-disc">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Entire Agreement
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        The Terms and our Privacy Policy constitute the sole and entire
        agreement between you and CFTC regarding the Portal and supersedes all
        prior and contemporaneous understandings, agreements, representations
        and warranties, both written and oral, regarding such subject matter.
      </p>
      <ol start={18} className="list-disk">
        <li>
          <Typography.Heading size="sm" className="mb-3">
            Reporting and Contact
          </Typography.Heading>
        </li>
      </ol>
      <p className="mb-3">
        Please send any reports of potential misuse, or any requests or comments
        relating to the Portal, to us at the following email address:{" "}
        <a href="mailto:admin@canadasforesttrust.ca">
          <strong>admin@canadasforesttrust.ca</strong>
        </a>
        .
      </p>
    </div>
  );
};

export default Terms;
