export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_ID as string;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag("event", "page_view", {
    page_location: url,
    page_title: document.title,
    send_to: GA_MEASUREMENT_ID,
  });
};

export const event = ({
  name,
  params,
}: {
  name: string;
  params?: { [key: string]: any };
}) => {
  window.gtag("event", name, params);
};

const gtag = {
  GA_MEASUREMENT_ID,
  pageview,
  event,
};

export default gtag;
